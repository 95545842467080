import { createRoot } from 'react-dom/client';
import { Box, ChakraProvider } from "@chakra-ui/react";
import App from "./App";
import 'react-toastify/dist/ReactToastify.css';
import './locales/i18n';

const root = createRoot(document.getElementById('root'));

root.render(
    <ChakraProvider>
      <Box backgroundColor={"#f4f4f4"} minH="100vh">
        <App />
      </Box>
    </ChakraProvider>
);
